import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import SportInfra from '../components/SportInfra';
import Topbar from '../components/Topbar';

const SportInfraPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | InfraStructure">
            <Topbar />
            <NavOne />
            <PageHeader title="Sports Facilities" />
            <SportInfra />
            <Footer />
        </Layout>
    );
};

export default SportInfraPage;
